import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Tour from '../components/tour'
import { ParallaxProvider } from 'react-scroll-parallax';

const ProductTourPage = () => {
  
  const lang = "ar";

  return (
    <Layout lang={lang}>
      <SEO title="Product Tour" />
      <ParallaxProvider>
        <Tour lang={lang}/>
      </ParallaxProvider>
    </Layout>
  )
}
export default ProductTourPage